const React = require('react');
const { useState, useRef, useEffect, useContext } = require('react');
const { string, shape, func, bool, arrayOf } = require('prop-types');
const classnames = require('classnames');
const Button = require('@andes/button');
const AndesForm = require('@andes/form');
const TextField = require('@andes/textfield');
const StaticPropsContext = require('../../../context/static-props');

const StyledLabel = require('../../../styled-label');
const { trackEvent } = require('../../../../lib/tracking');
const useIsMounted = require('../../../../hooks/use-mounted');

const namespace = 'ui-qadb__make-question-sa';
const MakeQuestionSA = ({
  id,
  title,
  item_id,
  action,
  disabled,
  onCreateQuestion,
  onChangeQuestion,
  url,
  app,
  subtitles,
  initialValue,
  track,
  parameters,
}) => {
  const { deviceType } = useContext(StaticPropsContext);
  const [value, setValue] = useState(initialValue || null);
  const [disabledBotton, setDisabledButton] = useState(disabled);
  const [isFocus, setFocus] = useState(false);
  const inputRef = useRef(null);
  const isMobile = deviceType === 'mobile';
  const fullWidthButton = !!isMobile;
  const isMounted = useIsMounted();

  useEffect(() => {
    setDisabledButton(disabled);
    setValue(initialValue);
  }, [initialValue, disabled]);

  const onInputChange = e => {
    setValue(e.target.value);
    setFocus();
  };

  const onInputFocus = () => {
    if (track) {
      trackEvent(track);
    }
  };

  const handleMakeQuestion = e => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (value && value.trim() !== '') {
      setDisabledButton(true);
      onChangeQuestion(value.trim());
      onCreateQuestion({
        item_id,
        question: value.trim(),
        failure: action.failure,
        success: action.success,
        app,
        parameters,
        form: id,
      });
    } else {
      inputRef.current.focus();
    }
  };

  return (
    <div className={namespace}>
      {title && (
        <StyledLabel
          as="p"
          color="BLACK"
          font_family="SEMIBOLD"
          font_size="MEDIUM"
          className={`${namespace}__title`}
          text={title}
        />
      )}
      <AndesForm className={`${namespace}__form`} action={url} method="POST" onSubmit={handleMakeQuestion}>
        <input type="hidden" name="itemId" value={item_id} />
        <div className={`${namespace}__content`}>
          <TextField
            name="question"
            data-testid="questions-input"
            className={classnames([`${namespace}__input`, !subtitles.length && 'no-message'])}
            placeholder={action.question_place_holder}
            setField={innerRef => {
              inputRef.current = innerRef;
            }}
            textbox
            helper={subtitles && subtitles.map(s => s.text).join('')}
            autoComplete="off"
            value={value}
            onChange={onInputChange}
            onFocus={onInputFocus}
            messageShow
            maxLength={2000}
            multiline
            focus={isFocus}
          />
        </div>
        <Button
          data-testid="questions-button"
          className={`${namespace}__button`}
          hierarchy="loud"
          type="submit"
          disabled={!isMounted || disabledBotton}
          fullWidth={fullWidthButton}
        >
          {action.text}
        </Button>
      </AndesForm>
    </div>
  );
};

MakeQuestionSA.propTypes = {
  id: string.isRequired,
  app: string.isRequired,
  title: string.isRequired,
  initialValue: string,
  subtitles: arrayOf(shape({})),
  item_id: string.isRequired,
  url: string.isRequired,
  action: shape({
    text: string.isRequired,
  }).isRequired,
  disabled: bool,
  onCreateQuestion: func.isRequired,
  onChangeQuestion: func.isRequired,
  track: shape({}),
  parameters: shape({}),
};

MakeQuestionSA.defaultProps = {
  disabled: false,
  track: null,
  subtitles: [],
  parameters: null,
};

module.exports = MakeQuestionSA;
